import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FaArrow, FaArrowCircleRight, FaBars, FaTimes, FaFacebook, FaInstagram, FaTiktok, FaWhatsapp } from 'react-icons/fa'
import Nav from '../Components/Nav'


function ChildDetails() {



    return (
        <div className='container'>

            <div>

                <Nav />
            </div>

            <div>
                <h1>Admission Form</h1>

                <div>
                    <h2>Childs Details</h2>
                    <form>
                        <div className='inputs'>

                            <label className='val'>First Name : </label>
                            <input className='valids' placeholder='*Enter First Name' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>Middle Name : </label>
                            <input className='valids' placeholder='*Enter Middle Name' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>Last Name : </label>
                            <input className='valids' placeholder='*Enter Last Name' />
                        </div>

                        <div className='inputs'>
                            <label className='val'>Age : </label>
                            <input className='valids' placeholder='*Enter Age ' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>Gender : </label>
                            <input className='valids' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>Birth Certificate Number : </label>
                            <input className='valids' placeholder='*123456789' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>Last School Attended : </label>
                            <input className='valids' placeholder='*Enter Last School Attended' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>Pr : </label>
                            <input className='valids' placeholder='*Enter Last School Attended' />
                        </div>
                        <div className='inputs'></div>
                        <div className='inputs'>
                            <label className='val'>Nationality : </label>
                            <input className='valids' placeholder='*Enter Nationality' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>Religion : </label>
                            <input className='valids' placeholder='*Enter Religion' />
                        </div>

                        <div className='inputs'>
                            <label className='val'>Description Of Any Learning Difficulty : </label>
                            <input type='date' className='valids' />
                        </div>

                        <div className='inputss'>
                            <button className='btn btn-warning sub'>Submit</button>
                        </div>
                    </form>

                </div>
            </div>

        </div >

    )
}

export default ChildDetails