import { useRef } from "react";
import { FaBars, FaTimes, FaFacebook, FaInstagram, FaTiktok, FaWhatsapp } from "react-icons/fa";

function Navbar() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle(
      "responsive_nav"
    );
  };

  return (
    <header>
      <h3>Kakamega Hill School</h3>
      <nav ref={navRef}>

        <div className='social'>
          <div className='socials'><FaWhatsapp /></div>
          <div className='socials'><FaFacebook /></div>
          <div className='socials'><FaInstagram /></div>
          <div className='socials'><FaTiktok /></div>
        </div>
        <div className="navs" >Portal</div>
        <div className="navs" >Register </div>
        <div className="navs" >Requirements</div>
        <div className="navs" >Contact Us</div>


        <button
          className="nav-btn nav-close-btn"
          onClick={showNavbar}>
          <FaTimes />
        </button>
      </nav>
      <button
        className="nav-btn"
        onClick={showNavbar}>
        <FaBars />
      </button>
    </header>
  );
}

export default Navbar;
