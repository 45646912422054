import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FaArrow, FaArrowCircleRight, FaBars, FaTimes, FaFacebook, FaInstagram, FaTiktok, FaWhatsapp } from 'react-icons/fa'
import Nav from '../Components/Nav'


function Index() {



    return (
        <div className='container'>

            <div className='indexing'>

            <div>

                <Nav />
            </div>

            <div className='home'>
                <h1>Kakamega Hill School Portal</h1>

                <div>

                    <button className='btn btn-danger'>Online Admission</button>
                </div>

                <div>

                </div>
            </div>
            </div>
            

        </div >

    )
}

export default Index