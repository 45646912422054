import React from 'react'
import Nav from './Nav'

function Parentsinfo() {
    return (
        <div className='container'>

            <div>
                <Nav />
            </div>
            <div>

                <h1>Parents Details</h1>
                <div>

                    <h2>Parent/Guardian Details</h2>
                    <form>
                        <div className='inputs'>

                            <label className='val'>First Name : </label>
                            <input className='valids' placeholder='*Enter First Name' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>Middle Name : </label>
                            <input className='valids' placeholder='*Enter Middle Name' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>Last Name : </label>
                            <input className='valids' placeholder='*Enter Last Name' />
                        </div>

                        <div className='inputs'>
                            <label className='val'>ID/Passport Number : </label>
                            <input className='valids' placeholder='*Enter Age ' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>Phone Number : </label>
                            <input className='valids' />
                        </div>

                        <div className='inputs'>
                            <input className='valids' placeholder='*Enter Nationality' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>  Alternate Phone Number    : </label>
                            <input className='valids' placeholder='*Enter Religion' />
                        </div>

                        <div className='inputs'>
                            <label className='val'>Occupation : </label>
                            <input type='date' className='valids' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>Marital Status : </label>
                            <input type='date' className='valids' />
                        </div>

                      
                    </form>

                    <h2>Parent/Guardian Details</h2>

                    <div>

                    <form>
                        <div className='inputs'>

                            <label className='val'>First Name : </label>
                            <input className='valids' placeholder='*Enter First Name' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>Middle Name : </label>
                            <input className='valids' placeholder='*Enter Middle Name' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>Last Name : </label>
                            <input className='valids' placeholder='*Enter Last Name' />
                        </div>

                        <div className='inputs'>
                            <label className='val'>ID/Passport Number : </label>
                            <input className='valids' placeholder='*Enter Age ' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>Phone Number : </label>
                            <input className='valids' />
                        </div>

                        <div className='inputs'>
                            <input className='valids' placeholder='*Enter Nationality' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>  Alternate Phone Number    : </label>
                            <input className='valids' placeholder='*Enter Religion' />
                        </div>

                        <div className='inputs'>
                            <label className='val'>Occupation : </label>
                            <input type='date' className='valids' />
                        </div>
                        <div className='inputs'>
                            <label className='val'>Marital Status : </label>
                            <input type='date' className='valids' />
                        </div>

                        <div className='inputss'>
                            <button className='btn btn-info sub'>Submit</button>
                        </div>
                    </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Parentsinfo