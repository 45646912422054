// src/components/SubNavbar.js
import React from 'react';

function SubNavbar() {
  return (
    <div className="sub-navbar">
      <ul>
        <li>
          <a href="/service1">Service 1</a>
        </li>
        <li>
          <a href="/service2">Service 2</a>
        </li>
        <li>
          <a href="/service3">Service 3</a>
        </li>
      </ul>
    </div>
  );
}

export default SubNavbar;
