import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Index from './Components/Index'
import Nav from './Components/Nav'
import ParentsInfo from './Components/Parentsinfo'
import SubNavbar from './Components/SubNavbar'
import ChildDetails from './Components/ChildsDetails'
import Transport from './Components/Transport'





function App() {
  return (
    <div className="App">

      <Router>

        <Routes>
          <Route exact path='/' element={<Index />} />

          <Route path='Nav' element={<Nav />} />
          <Route path='ParentsInfo' element={<ParentsInfo />} />
          <Route path='SubNavbar' element={<SubNavbar />} />
          <Route path='Transport' element={<Transport />} />
          <Route path='ChildsDetail' element={<ChildDetails />} />


        </Routes>

      </Router>

    </div>
  );
}

export default App;
